<template>
  <vca-card v-if="testLoginEnabled">
    <vca-input
      type="email"
      v-model="test_email"
      :placeholder="$t('profile.profile.email')"
    />
    <strong>Demonutzer</strong><br />
    <a href="#" @click="setMail('test_user@vivaconagua.org')"
      >test_user@vivaconagua.org</a
    >
    (Member)<br />
    <a href="#" @click="setMail('test_employee@vivaconagua.org')"
      >test_employee@vivaconagua.org</a
    >
    (Member, Employee)<br />
    <a href="#" @click="setMail('test_admin@vivaconagua.org')"
      >test_admin@vivaconagua.org</a
    >
    (Member, Employee, Admin)<br />
    <button class="vca-button" @click="test_login()">
      {{ $t("button.signin") }}
    </button>
  </vca-card>
  <vca-card v-else>
    <vca-card class="text-center shadowed">
      <h1>{{ $t("messages.login.redirect") }}</h1>
    </vca-card>
  </vca-card>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "LoginView",
  data() {
    return {
      callbackRoute: "/",
    };
  },
  props: {
    code: {
      type: String,
      default: null,
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.callbackRoute = from.path;
    });
  },
  mounted() {
    if (!this.testLoginEnabled) {
      if (!this.session) {
        this.$store.commit("user/loginCallback", this.callbackRoute);
        window.location.href = this.$store.getters["login_uri"];
      } else {
        this.$router.push({ path: "/events/list" });
      }
    }
  },
  methods: {
    test_login() {
      this.$store.dispatch("test_login").then(() => {
        this.$router.push({ path: "/events/list" });
      });
    },
    setMail(mail) {
      this.test_email = mail;
    },
  },
  computed: {
    ...mapGetters({
      session: "session",
    }),
    test_email: {
      set(value) {
        this.$store.state.test_email.email = value;
      },
      get() {
        return this.$store.state.test_email.email;
      },
    },
    testLoginEnabled() {
      return process.env.VUE_APP_TEST_LOGIN === "true";
    },
  },
};
</script>
